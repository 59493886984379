import React from "react";
import styled from "styled-components";
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;

`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  //padding-top: 100px;
  padding-bottom: 50px;
`;
const Title = styled.h2`
  font-size: 1.75rem;
  font-weight: 600;
  text-align: center;
  margin: 0;
  padding: 0 0 50px 0;
  color: #ffc107;
  @media (max-width: 1200px) {
    font-size: calc(1.3rem + .6vw);
  }
  @media (max-width: 768px) {
    padding: 0;
  }
`;
const Image = styled(Img)`
  background-color: #ffffff !important;
  max-width: 500px;
  max-height: 500px;
  width: 160px;
  margin: auto;
  @media (max-width: 768px) {
    max-width: 300px;
    max-height: 300px;

  }
`;

const ContainerImage = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 200px;
	height: 200px;
	margin: 20px;
	border: 1px solid #ffc107;
	border-radius: 20px;
  box-shadow: 0 0 20px rgb(0 0 0 / 50%)
	
	
`;
const Clientes = () => {
	const images = useStaticQuery(graphql`
	query {
 		ksb: file(name: { eq: "ksb" }, extension: { eq: "webp" }) {
 			childImageSharp {
 				fluid(maxWidth: 160, maxHeight: 160, quality: 100, fit: CONTAIN, background: "white") {
					...GatsbyImageSharpFluid
				}
 			}
 		}
 		recupac: file(name: { eq: "recupac" }, extension: { eq: "webp" }) {
 			childImageSharp {
 				fluid(maxWidth: 160, maxHeight: 160, quality: 100, fit: CONTAIN, background: "white") {
 					...GatsbyImageSharpFluid
 				}
 			}
 		}
 		romeral: file(name: { eq: "romeral" }, extension: { eq: "webp" }) {
 			childImageSharp {
 				fluid(maxWidth: 160, maxHeight: 160, quality: 100, fit: CONTAIN, background: "white") {
 					...GatsbyImageSharpFluid
 				}
 			}
 		} 
 		cmpc: file(name: { eq: "cmpc" }, extension: { eq: "webp" }) {
 			childImageSharp {
 				fluid(maxWidth: 160, maxHeight: 160, quality: 100, fit: CONTAIN, background: "white") {
 					...GatsbyImageSharpFluid
 				}
 			}
 		} 
 	}
`);
	return (
		<Section id={"clientes"}>
			<Title>Clientes</Title>

			<Container>
				<ContainerImage>
					<Image fluid={images.ksb.childImageSharp.fluid} alt="KSB"/>
				</ContainerImage>
				<ContainerImage>
					<Image fluid={images.recupac.childImageSharp.fluid} alt="Recupac"/>
				</ContainerImage>
				<ContainerImage>
					<Image fluid={images.romeral.childImageSharp.fluid} alt="Romeral"/>
				</ContainerImage>
				<ContainerImage>
					<Image fluid={images.cmpc.childImageSharp.fluid} alt="CMPC"/>
				</ContainerImage>
			</Container>
		</Section>
	);
}

export default Clientes;
