import React from "react";
import styled from "styled-components";
import Gearbox from "./Gearbox";

const ContainerInicio = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #000;
  padding-top: 100px;
  padding-bottom: 50px;
	@media (max-width: 768px) {
		padding-top: 50px;
		padding-bottom: 0;
  }
`;

const ContainerBox = styled.div`
  display: flex;
  flex-direction: column;

  //width: 100%;

  @media (min-width: 576px) {
    width: 540px;
  }
  @media (min-width: 768px) {
    width: 720px;
  }
  @media (min-width: 992px) {
    width: 960px;
		flex-direction: row;
  }
  @media (min-width: 1200px) {
    width: 1140px;
  }
`

const Title = styled.h1`
  margin-bottom: 1.5rem !important;
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 1.2;
	padding: 10px;
  @media (max-width: 1200px) {
    font-size: calc(1.375rem + 1.5vw)
  }
`;

const SpanTitle = styled.span`
  color: #0d6efd;
  font-weight: 600;
`;


const ColInicio = styled.div`
  width: 100%;
	margin-bottom: 5vh;
	

`;

const Parragraph = styled.p`
  color: #6c757d;
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
  padding: 0 15px 0.5rem;
	text-align: justify;

`;

const Boton = styled.a`
  background-color: #ffc107;
  border-color: #ffc107;
  color: #000;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  padding: 0.7rem 0.75rem;
	margin-left: 15px;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: none;
`;

const Arrow = styled.span`
	margin-left: 5px;
`;

const Inicio = () => {
	return (
		<ContainerInicio>
			<ContainerBox>
				{/* <p className="text-uppercase text-primary font-weight-medium f-14 mb-4">
                SOMEIN
              </p> */}
				<ColInicio>

					<Title>
						Sociedad Mecánica e Ingenieria{" "}
						<SpanTitle>Ltda</SpanTitle>
					</Title>
					<Parragraph>
						Somos una empresa con más de 27 años de experiencia y en constante crecimiento, especializada en servicios
						de mecánica industrial, desde nuestro inicio hemos destacado por el liderazgo en la fabricación de repuestos
						y equipos industriales, brindando a nuestros clientes la calidad en áreas de ingeniería y fabricación.
					</Parragraph>
					<Boton href="#contacto" >
						Contactanos<Arrow>&#8594;</Arrow>
					</Boton>
				</ColInicio>
				<ColInicio>
					<Gearbox/>
				</ColInicio>
			</ContainerBox>
		</ContainerInicio>
	)
}

export default Inicio
