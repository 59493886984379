import React, {useState} from "react";
import styled from "styled-components";
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";
import Lightbox from 'react-spring-lightbox';
import {FaTimes} from "@react-icons/all-files/fa/FaTimes";
import {FaChevronRight} from "@react-icons/all-files/fa/FaChevronRight";
import {FaChevronLeft} from "@react-icons/all-files/fa/FaChevronLeft";



const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-bottom: 50px;
`;

const Title = styled.h2`
  font-size: 1.75rem;
  font-weight: 600;
  text-align: center;
  margin: 0;
  padding: 0 0 50px 0;
  color: #ffc107;
  @media (max-width: 1200px) {
    font-size: calc(1.3rem + .6vw);
  }
  @media (max-width: 768px) {
    padding: 0;
  }
`;

const TitleService = styled.h2`
  //font-size: calc(24px + (48 - 24) * ((100vw - 300px) / (1600 - 300)));
  //font-weight: 400;
  align-self: flex-start;
  margin: 20px 0;
  padding: 0;
`;

const Image = styled(Img)`
  background-color: transparent;
  max-width: 500px;
  max-height: 500px;
  width: 80%;
  height: 80%;
  //margin: auto;
  @media (max-width: 768px) {
    max-width: 300px;
    max-height: 300px;

  }
`;

const ContainerImgLeft1 = styled.div`
  width: 100%;
  justify-content: flex-end;
  align-items: 	center;
  height: 100%;
  display: flex;
  grid-area: ContainerImgLeft1;
  @media (max-width: 768px) {
		justify-content: center;
  }
`;

const ContainerImgRight2 = styled.div`
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  display: flex;
  grid-area: ContainerImgRight2;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;
const ContainerImgLeft3 = styled.div`
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  display: flex;
  grid-area: ContainerImgLeft3;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;
const ContainerImgRight4 = styled.div`
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  display: flex;
  grid-area: ContainerImgRight4;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const ContainerTextRight1 = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-self: center;
  margin: 0 10%;
  grid-area: ContainerTextRight1;
  @media (max-width: 960px) {
    width: 80%;
  }
  @media (max-width: 768px) {
  }
`;

const ContainerTextLeft2 = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 10%;
  justify-self: center;
  grid-area: ContainerTextLeft2;
  @media (max-width: 960px) {
    width: 80%;
  }

`;
const ContainerTextRight3 = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 10%;
  justify-self: center;
  grid-area: ContainerTextRight3;
  @media (max-width: 960px) {
    width: 80%;
  }
`;

const ContainerTextLeft4 = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 10%;
  justify-self: center;
  grid-area: ContainerTextLeft4;
  @media (max-width: 960px) {
    width: 80%;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1.3fr 0.4fr 1.3fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  gap: 30px 0;
  grid-auto-flow: row;
  grid-template-areas:
    "ContainerImgLeft1 ContainerTextRight1 ContainerTextRight1"
    "ContainerTextLeft2 ContainerTextLeft2 ContainerImgRight2"
    "ContainerImgLeft3  ContainerTextRight3 ContainerTextRight3"
		"ContainerTextLeft4 ContainerTextLeft4 ContainerImgRight4";
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 0;
    grid-template-areas:
			"ContainerTextRight1"
			"ContainerImgLeft1"
			"ContainerTextLeft2"
			"ContainerImgRight2"
			"ContainerTextRight3"
			"ContainerImgLeft3"
			"ContainerTextLeft4"
			"ContainerImgRight4";
  }
`;

const Button = styled.a`
  background-color: #ffc107;
  border-color: #ffc107;
  color: #000;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  padding: 0.35rem 0.75rem;
  //margin-left: 15px;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: none;
  align-self: flex-start;
`;
const Arrow = styled.span`
  margin-left: 5px;
`;


const Servicios = () => {
	const [openEstructuras, setOpenEstructuras] = useState(false);
	const [openMecanizado, setOpenMecanizado] = useState(false);
	const [openFabyRep, setOpenFabyRep] = useState(false);
	const [currentImageIndex, setCurrentIndex] = useState(1);


	const images = useStaticQuery(graphql`
		query {
			mecanizado: file(name: { eq: "mecanizado" }, extension: { eq: "webp" }) {
				childImageSharp {
					fluid(maxWidth: 500, maxHeight: 500, quality: 100, fit: COVER) {
						...GatsbyImageSharpFluid
					}
				}
			}
			fabyrep: file(name: { eq: "fabyrep" }, extension: { eq: "webp" }) {
				childImageSharp {
					fluid(maxWidth: 500, maxHeight: 500, quality: 100, fit: COVER) {
						...GatsbyImageSharpFluid
					}
				}
			}
			estructuras: file(name: { eq: "estructuras" }, extension: { eq: "webp" }) {
				childImageSharp {
					fluid(maxWidth: 500, maxHeight: 500, quality: 100, fit: COVER) {
						...GatsbyImageSharpFluid
					}
				}
			}	
			asesoria: file(name: { eq: "asesoria" }, extension: { eq: "webp" }) {
				childImageSharp {
					fluid(maxWidth: 500, maxHeight: 500, quality: 100, fit: COVER) {
						...GatsbyImageSharpFluid
					}
				}
			}	
			estructurasfolder: allFile(filter: {relativeDirectory: {eq: "Estructuras"}}) {
				edges {
					node {
						childImageSharp {
							fluid(maxWidth: 500, maxHeight: 500, quality: 100, fit: COVER) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
			mecanizadofolder: allFile(filter: {relativeDirectory: {eq: "Mecanizado"}}) {
				edges {
					node {
						childImageSharp {
							fluid(maxWidth: 500, maxHeight: 500, quality: 100, fit: COVER) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
			fabyrepfolder: allFile(filter: {relativeDirectory: {eq: "FabyRep"}}) {
				edges {
					node {
						childImageSharp {
							fluid(maxWidth: 500, maxHeight: 500, quality: 100, fit: COVER) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
		}
	`)

	const gotoPrevious = () => {
		if(openMecanizado){
			currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1);
		}
		if(openEstructuras){
			currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1);
		}
		if(openFabyRep){
			currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1);
		}
	};
	const gotoNext = () => {
		if(openMecanizado){
			currentImageIndex < images.mecanizadofolder.edges.length - 1 && setCurrentIndex(currentImageIndex + 1);
		}
		if(openEstructuras){
			currentImageIndex < images.estructurasfolder.edges.length - 1 && setCurrentIndex(currentImageIndex + 1);
		}
		if(openFabyRep){
			currentImageIndex < images.fabyrepfolder.edges.length - 1 && setCurrentIndex(currentImageIndex + 1);
		}
	};



	return (
		<Section id={"servicios"}>
			<Title >Servicios</Title>
			<Container>
				<ContainerImgLeft1>
					<Image fluid={images.mecanizado.childImageSharp.fluid}/>
				</ContainerImgLeft1>
				<ContainerTextRight1>
					<TitleService>Mecanizado</TitleService>
					<p>
						Nuestra empresa, ofrece un servicio de alta calidad gracias a la
						tecnología de punta y la diversa gama de instrumentos de precisión, sumado esto a la
						experiencia y capacidad de nuestros operadores.
					</p>
					<Button 		href={"#"}
						onClick={() => {setOpenMecanizado(!openMecanizado);}}					>
						Ver Galeria <Arrow>&#8594;</Arrow>

					</Button>
					<Lightbox
						isOpen={openMecanizado}
						onPrev={gotoPrevious}
						onNext={gotoNext}
						images={images.mecanizadofolder.edges.map(({node}) => ({
							src: node.childImageSharp.fluid.src,
							srcSet: node.childImageSharp.fluid.srcSet,
						}))}
						currentIndex={currentImageIndex}
						/* Add your own UI */
						renderHeader={() => (
							<Header>
								<CloseButton onClick={() => {setOpenMecanizado(false); setCurrentIndex(0)}}>
									<CloseIcon/>
								</CloseButton>
							</Header>
						)}
						// renderFooter={() => (<CustomFooter />)}
						renderPrevButton={() => (
							<PrevButton
								onClick={() => {
									gotoPrevious()
								}}
							>
								<PrevIcon/>
							</PrevButton>
						)}
						renderNextButton={() => (
							<NextButton onClick={gotoNext}>
								<NextIcon/>
							</NextButton>
						)}
						style={{ background: 'rgba(0,0,0,0.43)' }}
						pageTransitionConfig={{
							from: { transform: "scale(0.75)", opacity: 0 },
							enter: { transform: "scale(1)", opacity: 1 },
							leave: { transform: "scale(0.75)", opacity: 0 },
							config: { mass: 1, tension: 320, friction: 32 }
						}}
					/>
				</ContainerTextRight1>
				<ContainerTextLeft2>
					<TitleService>Fabricación y Reparación</TitleService>
					<p>
						Contamos con personal capacitado para fabricar y reparar todo tipo de equipos, maquinarias presentes en la
						industría y estructuras metálicas.
					</p>
					<Button				href={"#"}		onClick={() => {							setOpenFabyRep(!openFabyRep)}}					>
						Ver Galeria <Arrow>&#8594;</Arrow>
					</Button>
					<Lightbox
						isOpen={openFabyRep}
						onPrev={gotoPrevious}
						onNext={gotoNext}
						images={images.fabyrepfolder.edges.map(({node}) => ({
							src: node.childImageSharp.fluid.src,
							srcSet: node.childImageSharp.fluid.srcSet,
						}))}
						currentIndex={currentImageIndex}
						/* Add your own UI */
						renderHeader={() => (
							<Header>
								<CloseButton onClick={() => {setOpenFabyRep(false); setCurrentIndex(0)}}>
									<CloseIcon/>
								</CloseButton>
							</Header>
						)}
						// renderFooter={() => (<CustomFooter />)}
						renderPrevButton={() => (
							<PrevButton
								onClick={() => {
									gotoPrevious()
								}}
							>
								<PrevIcon/>
							</PrevButton>
						)}
						renderNextButton={() => (
							<NextButton onClick={gotoNext}>
								<NextIcon/>
							</NextButton>
						)}
						style={{ background: 'rgba(0,0,0,0.43)' }}
						pageTransitionConfig={{
							from: { transform: "scale(0.75)", opacity: 0 },
							enter: { transform: "scale(1)", opacity: 1 },
							leave: { transform: "scale(0.75)", opacity: 0 },
							config: { mass: 1, tension: 320, friction: 32 }
						}}
					/>

				</ContainerTextLeft2>
				<ContainerImgRight2>
					<Image fluid={images.fabyrep.childImageSharp.fluid}/>
				</ContainerImgRight2>
				<ContainerImgLeft3>
					<Image fluid={images.estructuras.childImageSharp.fluid}/>
				</ContainerImgLeft3>
				<ContainerTextRight3>
					<TitleService>Estructuras Metálicas</TitleService>
					<p>
						Nuestra empresa cuenta con un área de estructuras metálicas de tamaño medio, que nos permite desarrollar
						trabajos especiales de estructura y diseños exclusivos y además fabricación de productos seriados y
						soldaduras en general.
					</p>
					<Button	href={"#"}	onClick={() => {setOpenEstructuras(true)}}>
						Ver Galeria <Arrow>&#8594;</Arrow>
					</Button>
					<Lightbox
						isOpen={openEstructuras}
						onPrev={gotoPrevious}
						onNext={gotoNext}
						images={images.estructurasfolder.edges.map(({node}) => ({
							src: node.childImageSharp.fluid.src,
							srcSet: node.childImageSharp.fluid.srcSet,
						}))}
						currentIndex={currentImageIndex}
						/* Add your own UI */
						renderHeader={() => (
							<Header>
								<CloseButton onClick={() => {setOpenEstructuras(false); setCurrentIndex(0)}}>
									<CloseIcon/>
								</CloseButton>
							</Header>
						)}
						// renderFooter={() => (<CustomFooter />)}
						renderPrevButton={() => (
							<PrevButton
								onClick={() => {
									gotoPrevious()
								}}
								>
								<PrevIcon/>
							</PrevButton>
						)}
						renderNextButton={() => (
							<NextButton onClick={gotoNext}>
								<NextIcon/>
							</NextButton>
						)}
						style={{ background: 'rgba(0,0,0,0.43)' }}
						pageTransitionConfig={{
						  from: { transform: "scale(0.75)", opacity: 0 },
						  enter: { transform: "scale(1)", opacity: 1 },
						  leave: { transform: "scale(0.75)", opacity: 0 },
						  config: { mass: 1, tension: 320, friction: 32 }
						}}
					/>
				</ContainerTextRight3>
				<ContainerTextLeft4>
					<TitleService>Asesorías</TitleService>
					<p>
						Ofrecemos servicios de asesorías en terreno, mejoras y diseños de distintos tipos de equipos presentes en la
						industria para mejorar los procesos productivos.
					</p>
				</ContainerTextLeft4>
				<ContainerImgRight4>
					<Image fluid={images.asesoria.childImageSharp.fluid}/>
				</ContainerImgRight4>

			</Container>
		</Section>
	);
}

const Header = styled.div`
	display: flex;
	justify-content: flex-end;
	padding: 0.5rem;
	background: rgba(0, 0, 0, 0.3);
`;

const CloseButton = styled.button`
  background: #202020;
  border: none;
  cursor: pointer;
  width: 3rem;
  height: 3rem;
	//border-radius: 50%;

`;

const CloseIcon = styled(FaTimes)`
	color: white;
	font-size: 1.5rem;
`;

const PrevButton = styled.button`
  background: rgba(0, 0, 0, 0.7);
  border: none;
  cursor: pointer;
	//border-radius:	50%;
  width: 50px;
  height: 50px;
  z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const PrevIcon = styled(FaChevronLeft)`
	color: white;
	font-size: 1.5rem;
`;

const NextButton = styled.button`
  background: rgba(0, 0, 0, 0.7);
	overflow: hidden;
  border: none;
  cursor: pointer;
  //border-radius:	50%;
  width: 50px;
  height: 50px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
\` ;
`;

const NextIcon = styled(FaChevronRight)`
	color: white;
	font-size: 1.5rem;
`;


export default Servicios;
